


import DATA_MASTER from '../library/dataMaster'
import FETCHING from '../library/fetching'
import UMUM from '../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        nik : '',
        nama_lengkap : '',
        tmp_lahir : '',
        tgl_lahir : '',
        jns_kelamin : '',
        alamat : '',
        dusun : '',
        rt_rw : '',
        kecamatan_id : '',
        des_kel_id : '',
        telp_hp : '',
        email : '',
        kode_pos : '',
        status_perkawinan_id : '',
        uraian_perkawinan : '',
        agama_id : '',
        uraian_agama : '',
        tinggi_bdn : '',
        berat_bdn : '',
        no_pendaftaran: '',
        status : '',
        keterangan : '',
        file : null,



        biodata_id : '',
        ditujukan : '',
        jabatan : '',
        lokasi : '',
        lokasi_wilayah : '',
        besaran_upah_id : '',
      },

      formPendidikan : {
        biodata_id : '',
      },

      formPengalaman : {
        biodata_id : '',
      },

      formJabatan : {
        id : '',
        biodata_id : '',
        ditujukan : '',
        jabatan : '',
        lokasi : '',
        lokasi_wilayah : '',
        besaran_upah_id : '',
      },

      formLampiran : {
        id : '',
        biodata_id : '',
        uraian : '',
        file : null
      },

      formKeterampilan : {
        biodata_id : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      listKecamatan : '',
      listDesKel : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      


     
      list_data : [],
      list_pendidikan : [],
      list_pengalaman : [],
      list_jabatan : [],
      list_lampiran : [],
      list_keterampilan : [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat_xxx : false,
      mdl_lihat : false,
      mdl_lihatx : false,
      btn_add: false,

      
      DATA_MASTER : DATA_MASTER,
      FETCHING : FETCHING,
      UMUM : UMUM,
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              kecamatan_id : this.form.kecamatan_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
            //   console.log(res_data);
      });
    },

    getViewPendidikan : function(idku){
      // console.log(idku)
      this.formPendidikan.biodata_id = idku
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_CETAK_KARTU + "view_cetak", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            biodata_id : this.formPendidikan.biodata_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.list_pendidikan = res_data;
            this.$store.commit("hideLoading");
            // console.log(res_data);
      });
    },

    getViewKeterampilan: function(idku){
    //   console.log(idku)
      this.formKeterampilan.biodata_id = idku
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_CETAK_KARTU + "view_keterampilan", {
    //   fetch(this.$store.state.url.URL_BIO_KETERAMPILAN + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            biodata_id : this.formKeterampilan.biodata_id
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.list_keterampilan = res_data;
            console.log(res_data);
      });
    },

    selectData : function(data){
        // console.log(data)
        this.getViewPendidikan(data.id);
        this.getViewKeterampilan(data.id);
        this.form.id = data.id;
        this.form.nik = data.nik;
        this.form.tmp_lahir = data.tmp_lahir;
        this.form.tgl_lahir = data.tgl_lahir;
        this.form.jns_kelamin = data.jns_kelamin;
        this.form.alamat = data.alamat;
        this.form.dusun = data.dusun;
        this.form.rt_rw = data.rt_rw;
        this.form.kecamatan_id = data.kecamatan_id;
        this.form.nama_kecamatan = data.nama_kecamatan;
        this.form.des_kel_id = data.des_kel_id;
        this.form.nama_des_kel = data.nama_des_kel;
        this.form.kode_pos = data.kode_pos;
        this.form.status_perkawinan_id = data.status_perkawinan_id;
        this.form.uraian_perkawinan = data.uraian_perkawinan;
        this.form.agama_id = data.agama_id;
        this.form.uraian_agama = data.uraian_agama;
        this.form.tinggi_bdn = data.tinggi_bdn;
        this.form.berat_bdn = data.berat_bdn;
        this.form.status = data.status;
        this.form.keterangan = data.keterangan;
        this.form.no_pendaftaran = data.no_pendaftaran;
        this.form.pertamax = data.pertamax;
        this.form.keduax = data.keduax;
        this.form.ketigax = data.ketigax;
        this.form.file = data.file;
        this.file_old = data.file;


        this.formKeterampilan.id = data.id;
        this.formKeterampilan.biodata_id = data.biodata_id;
        this.formKeterampilan.uraian = data.uraian;;
        this.formKeterampilan.tahun = data.tahun;

        this.form.username = data.username;
        this.form.nama = data.nama;
        this.form.hp = data.hp;
        this.form.email = data.email;


        // this.formJabatan.id = data.id;
        // this.formJabatan.biodata_id = data.biodata_id;
        // this.formJabatan.ditujukan = data.ditujukan;
        // this.formJabatan.jabatan = data.jabatan;
        // this.formJabatan.lokasi = data.lokasi;
        // this.formJabatan.lokasi_wilayah = data.lokasi_wilayah;
        // this.formJabatan.besaran_upah_id = data.besaran_upah_id;
        // this.formJabatan.uraian_besaran_upah = data.uraian_besaran_upah;
    },

    // selectDataJabatan : function(data){
    //     console.log(data)
    //     this.formJabatan.id = data.id;
    //     this.formJabatan.biodata_id = data.biodata_id;
    //     this.formJabatan.ditujukan = data.ditujukan;
    //     this.formJabatan.jabatan = data.jabatan;
    //     this.formJabatan.lokasi = data.lokasi;
    //     this.formJabatan.lokasi_wilayah = data.lokasi_wilayah;
    //     this.formJabatan.besaran_upah_id = data.besaran_upah_id;
    //     this.formJabatan.uraian_besaran_upah = data.uraian_besaran_upah;
    // },

    selectDataLampiran : function(data){
        this.formLampiran.id = data.id;
        this.formLampiran.biodata_id = data.biodata_id;
        this.formLampiran.uraian = data.uraian;;
        this.formLampiran.file = data.file;
        this.file_old = data.file;
    },

    selectDataKeterampilan : function(data){
        this.formKeterampilan.id = data.id;
        this.formKeterampilan.biodata_id = data.biodata_id;
        this.formKeterampilan.uraian = data.uraian;;
        this.formKeterampilan.tahun = data.tahun;
    },

    terima_data : function() {
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("keterangan", this.form.keterangan);

      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "terima_data", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    tolak_data : function() {
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("keterangan", this.form.keterangan);

      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "tolak_data", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    cetakData : function() {
        var id = this.form.id;
        var URLku = this.$store.state.url.URL_LAPORAN + 'query/?id='+id+''
        // var URLku = this.$store.state.url.URL_LAPORAN+'pdf/?id='+id+''
        window.open(URLku);
    },

    cetakAK2 : function() {
        var id = this.form.id;
        var URLku = this.$store.state.url.URL_LAPORAN + 'query_ak2/?id='+id+' '
        console.log(URLku);
    },

    printTable () {
        const printWindow = window.open('', '_blank');
        const content = document.getElementById('printCetak').innerHTML;
        const style = `
            <link rel="stylesheet" type="text/css" href="css/bg.css">
            <link rel="stylesheet" type="text/css" href="css/charku.css">
            <link rel="stylesheet" type="text/css" href="css/huruf.css">
            <link rel="stylesheet" type="text/css" href="css/input.css">
            <link rel="stylesheet" type="text/css" href="css/kiken.css">
            <link rel="stylesheet" type="text/css" href="css/maps.css">
            <link rel="stylesheet" type="text/css" href="css/modal.css">
            <style>
            table, td, th {  
                border: 1px solid #ddd;
            }

            .frame_cari{
                padding-left: 1%;
                padding-right: 1%;
            }

            .h_table_head{
                height: 50px;
                color: #282828;
            }

            .h_table_body{
                color: #343434;
                font-size: 13px;
                font-weight: 530;
            }

            table, td {  
            border: 1px solid #ddd;
            }
            
            table {
                border-collapse: collapse;
                width: 100%;
            }
            
            th, td {
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            tr:nth-child(even) {background-color: #eeeeee;}


            .fab_kecil{
                width: 45px;
                height: 20px;
            }


            .tbl_responsive{
            overflow-x:auto;
            }

            .catat{
            margin: 5px; 
            border-bottom: 2px solid #383838; 
            display: grid; 
            grid-template-columns: 
            1fr 4fr 1fr; 
            grid-template-rows: 1fr; 
            grid-column-gap: 0px; 
            grid-row-gap: 0px;
        }

        .bsre {
            display: grid;
            grid-template-columns: 6fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: px;
        }

        footer{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100px;
            width: 100%;
            overflow:hidden;
            font-size: 11.5px;
        }

            </style>
        `;

        printWindow.document.write('<html><head><title>Dinas Transmigrasi dan Tenaga Kerja Kab. Konawe Selatan</title>' + style + '</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer><div class="catat"><div style="margin-top: 7.5px">Catatan : </div><div></div><div></div></div><div class="bsre"><div><ul><li>UU ITE No 11 Tahun 2008 Pasal 5 Ayat 1 <br> <i>"Informasi Elektronik dan/atau Dokumen Elektronik dan/atau hasil cetaknya merupakan alat bukti hukum yang sah."</i> </li><li>Dokumen ini telah ditandatangani secara elektronik menggunakan <strong>sertifikat elektronik</strong> yang diterbitkan <strong>BSrE.</strong></li></ul></div><div style="margin-top: 5px"><img alt="" src="https://api-tte.kominfo.go.id//images/organizers/5440b80db1c706b6cee1df1ad151f74e.png" style="width:100px; border: 1px solid #383838;" /></div></div></footer></body></html>');
        printWindow.document.close();
        printWindow.print();
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


    // ====================================== PAGINATE ====================================

  },

  mounted () {
    DATA_MASTER.getAgama();
    DATA_MASTER.getStatusPerkawinan();
    DATA_MASTER.getKecamatan();
    DATA_MASTER.getBahasa();
    this.getView();
  },
}
